/*! base.css v1.1 */

/* ==========================================================================
   HTML5 display definitions
   KhangTQQ Editor
   ========================================================================== */

/**
 * Correct `block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

 article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}
/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio, canvas, video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}
/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}
/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}
/* ==========================================================================
   Base
   ========================================================================== */

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%;
    /* 1 */
    -ms-text-size-adjust: 100%;
    /* 2 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
    height: 100%;
}
/**
 * Address `font-family` inconsistency between `textarea` and other form
 * elements.
 */

html, button, input, select, textarea {
    font-family: "メイリオ", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;
}
/**
 * Address margins handled incorrectly in IE 6/7.
 */

body {
    margin: 0;
    font-family: "メイリオ", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;
}
/* ==========================================================================
   Links
   ========================================================================== */

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a {
    text-decoration: none;
    color: #000;
}
a:focus {
    outline: none;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}
/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active, a:hover {
    outline: 0;
    text-decoration: none;
    color: #FFD702;
}
/* ==========================================================================
   Typography
   ========================================================================== */

/**
 * Address font sizes and margins set differently in IE 6/7.
 * Address font sizes within `section` and `article` in Firefox 4+, Safari 5,
 * and Chrome.
 */

h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    margin: 0em;
}
/**
 * Address styling not present in IE 7/8/9, Safari 5, and Chrome.
 */

abbr[title] {
    border-bottom: 1px dotted;
}
/**
 * Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
 */

b, strong {
    font-weight: bold;
}
blockquote {
    margin: 1em 40px;
}
/**
 * Address styling not present in Safari 5 and Chrome.
 */

dfn {
    font-style: italic;
}
/**
 * Address differences between Firefox and other browsers.
 * Known issue: no IE 6/7 normalization.
 */

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}
/**
 * Address styling not present in IE 6/7/8/9.
 */

mark {
    background: #ff0;
    color: #000;
}
/**
 * Address margins set differently in IE 6/7.
 */

p, pre {
    margin: 0;
}
/**
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */

code, kbd, pre, samp {
    font-family: monospace, serif;
    _font-family: 'courier new', monospace;
    font-size: 1em;
}
/**
 * Improve readability of pre-formatted text in all browsers.
 */

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}
/**
 * Address CSS quotes not supported in IE 6/7.
 */

q {
    quotes: none;
}
/**
 * Address `quotes` property not supported in Safari 4.
 */

q:before, q:after {
    content: '';
    content: none;
}
/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
    font-size: 80%;
}
/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
/* ==========================================================================
   Lists
   ========================================================================== */

/**
 * Address margins set differently in IE 6/7.
 */

dl, menu, ol, ul {
    margin: 0;
}
dd {
    margin: 0;
}
/**
 * Address paddings set differently in IE 6/7.
 */

menu, ol, ul {
    padding: 0;
}
/**
 * Correct list images handled incorrectly in IE 7.
 */

nav ul, nav ol {
    list-style: none;
    list-style-image: none;
}
/**
 * All browser
 */

ul {
    list-style: none;
}
/* ==========================================================================
   Embedded content
   ========================================================================== */

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: 0;
    /* 1 */
    -ms-interpolation-mode: bicubic;
    /* 2 */
}
/**
 * Correct overflow displayed oddly in IE 9.
 */

svg:not(:root) {
    overflow: hidden;
}
/* ==========================================================================
   Figures
   ========================================================================== */

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}
/* ==========================================================================
   Forms
   ========================================================================== */

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}
/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0;
    padding: 0;
}
/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0;
    /* 1 */
    padding: 0;
    white-space: normal;
    /* 2 */
    *margin-left: 0;
    /* 3 */
}
/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button, input, select, textarea {
    font-size: 100%;
    /* 1 */
    margin: 0;
    /* 2 */
    vertical-align: baseline;
    /* 3 */
    *vertical-align: middle;
    /* 3 */
}
/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button, input {
    line-height: normal;
}
/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button, select {
    text-transform: none;
}
/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button, html input[type="button"],
/* 1 */

input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    /* 2 */
    cursor: pointer;
    /* 3 */
    *overflow: visible;
    /* 4 */
}
/**
 * Re-set default cursor for disabled elements.
 */

button[disabled], html input[disabled] {
    cursor: default;
}
/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
    *height: 13px;
    /* 3 */
    *width: 13px;
    /* 3 */
}
/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    /* 2 */
    box-sizing: content-box;
}
/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto;
    /* 1 */
    vertical-align: top;
    /* 2 */
}
/* ==========================================================================
   Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}
/**
 * REMOVE BACKGROUND INPUT CONFIRM
 */

input.clear_css:-webkit-autofill, textarea.clear_css:-webkit-autofill {
    -webkit-box-shadow: 0 1px 3px #c9c9c9 inset !important;
    transition: background-color 10s ease-in-out 0s;
}
input.clear_css:-webkit-autofill.wpcf7c-conf, textarea.clear_css:-webkit-autofill.wpcf7c-conf {
    -webkit-box-shadow: 0 0 0px 1000px #f7f9f6 inset !important;
    transition: background-color 10s ease-in-out 0s;
}

input, textarea{
    border-radius: 0;
/*    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;*/
}
/**
 * CLEAR BOTH
 */

.clearfix:after {
    display: block;
    clear: both;
    content: "";
}
/**
 * HOVER OPACITY A
 */

.hover {
    -webkit-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease;
}
.hover:hover {
    opacity: 0.8;
}
